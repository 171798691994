import _d3Selection from "d3-selection";
import _d3Dispatch from "d3-dispatch";
import _d3Timer from "d3-timer";
import _d3Interpolate from "d3-interpolate";
import _d3Color from "d3-color";
import _d3Ease from "d3-ease";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// https://d3js.org/d3-transition/ Version 1.1.0. Copyright 2017 Mike Bostock.
(function (global, factory) {
  factory(exports, _d3Selection, _d3Dispatch, _d3Timer, _d3Interpolate, _d3Color, _d3Ease);
})(exports, function (exports, d3Selection, d3Dispatch, d3Timer, d3Interpolate, d3Color, d3Ease) {
  'use strict';

  var emptyOn = d3Dispatch.dispatch("start", "end", "interrupt");
  var emptyTween = [];
  var CREATED = 0;
  var SCHEDULED = 1;
  var STARTING = 2;
  var STARTED = 3;
  var RUNNING = 4;
  var ENDING = 5;
  var ENDED = 6;

  var schedule = function (node, name, id, index, group, timing) {
    var schedules = node.__transition;
    if (!schedules) node.__transition = {};else if (id in schedules) return;
    create(node, id, {
      name: name,
      index: index,
      // For context during callback.
      group: group,
      // For context during callback.
      on: emptyOn,
      tween: emptyTween,
      time: timing.time,
      delay: timing.delay,
      duration: timing.duration,
      ease: timing.ease,
      timer: null,
      state: CREATED
    });
  };

  function init(node, id) {
    var schedule = node.__transition;
    if (!schedule || !(schedule = schedule[id]) || schedule.state > CREATED) throw new Error("too late");
    return schedule;
  }

  function set(node, id) {
    var schedule = node.__transition;
    if (!schedule || !(schedule = schedule[id]) || schedule.state > STARTING) throw new Error("too late");
    return schedule;
  }

  function get(node, id) {
    var schedule = node.__transition;
    if (!schedule || !(schedule = schedule[id])) throw new Error("too late");
    return schedule;
  }

  function create(node, id, self) {
    var schedules = node.__transition,
        tween; // Initialize the self timer when the transition is created.
    // Note the actual delay is not known until the first callback!

    schedules[id] = self;
    self.timer = d3Timer.timer(schedule, 0, self.time);

    function schedule(elapsed) {
      self.state = SCHEDULED;
      self.timer.restart(start, self.delay, self.time); // If the elapsed delay is less than our first sleep, start immediately.

      if (self.delay <= elapsed) start(elapsed - self.delay);
    }

    function start(elapsed) {
      var i, j, n, o; // If the state is not SCHEDULED, then we previously errored on start.

      if (self.state !== SCHEDULED) return stop();

      for (i in schedules) {
        o = schedules[i];
        if (o.name !== self.name) continue; // While this element already has a starting transition during this frame,
        // defer starting an interrupting transition until that transition has a
        // chance to tick (and possibly end); see d3/d3-transition#54!

        if (o.state === STARTED) return d3Timer.timeout(start); // Interrupt the active transition, if any.
        // Dispatch the interrupt event.

        if (o.state === RUNNING) {
          o.state = ENDED;
          o.timer.stop();
          o.on.call("interrupt", node, node.__data__, o.index, o.group);
          delete schedules[i];
        } // Cancel any pre-empted transitions. No interrupt event is dispatched
        // because the cancelled transitions never started. Note that this also
        // removes this transition from the pending list!
        else if (+i < id) {
            o.state = ENDED;
            o.timer.stop();
            delete schedules[i];
          }
      } // Defer the first tick to end of the current frame; see d3/d3#1576.
      // Note the transition may be canceled after start and before the first tick!
      // Note this must be scheduled before the start event; see d3/d3-transition#16!
      // Assuming this is successful, subsequent callbacks go straight to tick.


      d3Timer.timeout(function () {
        if (self.state === STARTED) {
          self.state = RUNNING;
          self.timer.restart(tick, self.delay, self.time);
          tick(elapsed);
        }
      }); // Dispatch the start event.
      // Note this must be done before the tween are initialized.

      self.state = STARTING;
      self.on.call("start", node, node.__data__, self.index, self.group);
      if (self.state !== STARTING) return; // interrupted

      self.state = STARTED; // Initialize the tween, deleting null tween.

      tween = new Array(n = self.tween.length);

      for (i = 0, j = -1; i < n; ++i) {
        if (o = self.tween[i].value.call(node, node.__data__, self.index, self.group)) {
          tween[++j] = o;
        }
      }

      tween.length = j + 1;
    }

    function tick(elapsed) {
      var t = elapsed < self.duration ? self.ease.call(null, elapsed / self.duration) : (self.timer.restart(stop), self.state = ENDING, 1),
          i = -1,
          n = tween.length;

      while (++i < n) {
        tween[i].call(null, t);
      } // Dispatch the end event.


      if (self.state === ENDING) {
        self.on.call("end", node, node.__data__, self.index, self.group);
        stop();
      }
    }

    function stop() {
      self.state = ENDED;
      self.timer.stop();
      delete schedules[id];

      for (var i in schedules) return; // eslint-disable-line no-unused-vars


      delete node.__transition;
    }
  }

  var interrupt = function (node, name) {
    var schedules = node.__transition,
        schedule,
        active,
        empty = true,
        i;
    if (!schedules) return;
    name = name == null ? null : name + "";

    for (i in schedules) {
      if ((schedule = schedules[i]).name !== name) {
        empty = false;
        continue;
      }

      active = schedule.state > STARTING && schedule.state < ENDING;
      schedule.state = ENDED;
      schedule.timer.stop();
      if (active) schedule.on.call("interrupt", node, node.__data__, schedule.index, schedule.group);
      delete schedules[i];
    }

    if (empty) delete node.__transition;
  };

  var selection_interrupt = function (name) {
    return this.each(function () {
      interrupt(this || _global, name);
    });
  };

  function tweenRemove(id, name) {
    var tween0, tween1;
    return function () {
      var schedule = set(this || _global, id),
          tween = schedule.tween; // If this node shared tween with the previous node,
      // just assign the updated shared tween and we’re done!
      // Otherwise, copy-on-write.

      if (tween !== tween0) {
        tween1 = tween0 = tween;

        for (var i = 0, n = tween1.length; i < n; ++i) {
          if (tween1[i].name === name) {
            tween1 = tween1.slice();
            tween1.splice(i, 1);
            break;
          }
        }
      }

      schedule.tween = tween1;
    };
  }

  function tweenFunction(id, name, value) {
    var tween0, tween1;
    if (typeof value !== "function") throw new Error();
    return function () {
      var schedule = set(this || _global, id),
          tween = schedule.tween; // If this node shared tween with the previous node,
      // just assign the updated shared tween and we’re done!
      // Otherwise, copy-on-write.

      if (tween !== tween0) {
        tween1 = (tween0 = tween).slice();

        for (var t = {
          name: name,
          value: value
        }, i = 0, n = tween1.length; i < n; ++i) {
          if (tween1[i].name === name) {
            tween1[i] = t;
            break;
          }
        }

        if (i === n) tween1.push(t);
      }

      schedule.tween = tween1;
    };
  }

  var transition_tween = function (name, value) {
    var id = (this || _global)._id;
    name += "";

    if (arguments.length < 2) {
      var tween = get(this.node(), id).tween;

      for (var i = 0, n = tween.length, t; i < n; ++i) {
        if ((t = tween[i]).name === name) {
          return t.value;
        }
      }

      return null;
    }

    return this.each((value == null ? tweenRemove : tweenFunction)(id, name, value));
  };

  function tweenValue(transition, name, value) {
    var id = transition._id;
    transition.each(function () {
      var schedule = set(this || _global, id);
      (schedule.value || (schedule.value = {}))[name] = value.apply(this || _global, arguments);
    });
    return function (node) {
      return get(node, id).value[name];
    };
  }

  var interpolate = function (a, b) {
    var c;
    return (typeof b === "number" ? d3Interpolate.interpolateNumber : b instanceof d3Color.color ? d3Interpolate.interpolateRgb : (c = d3Color.color(b)) ? (b = c, d3Interpolate.interpolateRgb) : d3Interpolate.interpolateString)(a, b);
  };

  function attrRemove(name) {
    return function () {
      this.removeAttribute(name);
    };
  }

  function attrRemoveNS(fullname) {
    return function () {
      this.removeAttributeNS(fullname.space, fullname.local);
    };
  }

  function attrConstant(name, interpolate$$1, value1) {
    var value00, interpolate0;
    return function () {
      var value0 = this.getAttribute(name);
      return value0 === value1 ? null : value0 === value00 ? interpolate0 : interpolate0 = interpolate$$1(value00 = value0, value1);
    };
  }

  function attrConstantNS(fullname, interpolate$$1, value1) {
    var value00, interpolate0;
    return function () {
      var value0 = this.getAttributeNS(fullname.space, fullname.local);
      return value0 === value1 ? null : value0 === value00 ? interpolate0 : interpolate0 = interpolate$$1(value00 = value0, value1);
    };
  }

  function attrFunction(name, interpolate$$1, value) {
    var value00, value10, interpolate0;
    return function () {
      var value0,
          value1 = value(this || _global);
      if (value1 == null) return void this.removeAttribute(name);
      value0 = this.getAttribute(name);
      return value0 === value1 ? null : value0 === value00 && value1 === value10 ? interpolate0 : interpolate0 = interpolate$$1(value00 = value0, value10 = value1);
    };
  }

  function attrFunctionNS(fullname, interpolate$$1, value) {
    var value00, value10, interpolate0;
    return function () {
      var value0,
          value1 = value(this || _global);
      if (value1 == null) return void this.removeAttributeNS(fullname.space, fullname.local);
      value0 = this.getAttributeNS(fullname.space, fullname.local);
      return value0 === value1 ? null : value0 === value00 && value1 === value10 ? interpolate0 : interpolate0 = interpolate$$1(value00 = value0, value10 = value1);
    };
  }

  var transition_attr = function (name, value) {
    var fullname = d3Selection.namespace(name),
        i = fullname === "transform" ? d3Interpolate.interpolateTransformSvg : interpolate;
    return this.attrTween(name, typeof value === "function" ? (fullname.local ? attrFunctionNS : attrFunction)(fullname, i, tweenValue(this || _global, "attr." + name, value)) : value == null ? (fullname.local ? attrRemoveNS : attrRemove)(fullname) : (fullname.local ? attrConstantNS : attrConstant)(fullname, i, value + ""));
  };

  function attrTweenNS(fullname, value) {
    function tween() {
      var node = this || _global,
          i = value.apply(node, arguments);
      return i && function (t) {
        node.setAttributeNS(fullname.space, fullname.local, i(t));
      };
    }

    tween._value = value;
    return tween;
  }

  function attrTween(name, value) {
    function tween() {
      var node = this || _global,
          i = value.apply(node, arguments);
      return i && function (t) {
        node.setAttribute(name, i(t));
      };
    }

    tween._value = value;
    return tween;
  }

  var transition_attrTween = function (name, value) {
    var key = "attr." + name;
    if (arguments.length < 2) return (key = this.tween(key)) && key._value;
    if (value == null) return this.tween(key, null);
    if (typeof value !== "function") throw new Error();
    var fullname = d3Selection.namespace(name);
    return this.tween(key, (fullname.local ? attrTweenNS : attrTween)(fullname, value));
  };

  function delayFunction(id, value) {
    return function () {
      init(this || _global, id).delay = +value.apply(this || _global, arguments);
    };
  }

  function delayConstant(id, value) {
    return value = +value, function () {
      init(this || _global, id).delay = value;
    };
  }

  var transition_delay = function (value) {
    var id = (this || _global)._id;
    return arguments.length ? this.each((typeof value === "function" ? delayFunction : delayConstant)(id, value)) : get(this.node(), id).delay;
  };

  function durationFunction(id, value) {
    return function () {
      set(this || _global, id).duration = +value.apply(this || _global, arguments);
    };
  }

  function durationConstant(id, value) {
    return value = +value, function () {
      set(this || _global, id).duration = value;
    };
  }

  var transition_duration = function (value) {
    var id = (this || _global)._id;
    return arguments.length ? this.each((typeof value === "function" ? durationFunction : durationConstant)(id, value)) : get(this.node(), id).duration;
  };

  function easeConstant(id, value) {
    if (typeof value !== "function") throw new Error();
    return function () {
      set(this || _global, id).ease = value;
    };
  }

  var transition_ease = function (value) {
    var id = (this || _global)._id;
    return arguments.length ? this.each(easeConstant(id, value)) : get(this.node(), id).ease;
  };

  var transition_filter = function (match) {
    if (typeof match !== "function") match = d3Selection.matcher(match);

    for (var groups = (this || _global)._groups, m = groups.length, subgroups = new Array(m), j = 0; j < m; ++j) {
      for (var group = groups[j], n = group.length, subgroup = subgroups[j] = [], node, i = 0; i < n; ++i) {
        if ((node = group[i]) && match.call(node, node.__data__, i, group)) {
          subgroup.push(node);
        }
      }
    }

    return new Transition(subgroups, (this || _global)._parents, (this || _global)._name, (this || _global)._id);
  };

  var transition_merge = function (transition) {
    if (transition._id !== (this || _global)._id) throw new Error();

    for (var groups0 = (this || _global)._groups, groups1 = transition._groups, m0 = groups0.length, m1 = groups1.length, m = Math.min(m0, m1), merges = new Array(m0), j = 0; j < m; ++j) {
      for (var group0 = groups0[j], group1 = groups1[j], n = group0.length, merge = merges[j] = new Array(n), node, i = 0; i < n; ++i) {
        if (node = group0[i] || group1[i]) {
          merge[i] = node;
        }
      }
    }

    for (; j < m0; ++j) {
      merges[j] = groups0[j];
    }

    return new Transition(merges, (this || _global)._parents, (this || _global)._name, (this || _global)._id);
  };

  function start(name) {
    return (name + "").trim().split(/^|\s+/).every(function (t) {
      var i = t.indexOf(".");
      if (i >= 0) t = t.slice(0, i);
      return !t || t === "start";
    });
  }

  function onFunction(id, name, listener) {
    var on0,
        on1,
        sit = start(name) ? init : set;
    return function () {
      var schedule = sit(this || _global, id),
          on = schedule.on; // If this node shared a dispatch with the previous node,
      // just assign the updated shared dispatch and we’re done!
      // Otherwise, copy-on-write.

      if (on !== on0) (on1 = (on0 = on).copy()).on(name, listener);
      schedule.on = on1;
    };
  }

  var transition_on = function (name, listener) {
    var id = (this || _global)._id;
    return arguments.length < 2 ? get(this.node(), id).on.on(name) : this.each(onFunction(id, name, listener));
  };

  function removeFunction(id) {
    return function () {
      var parent = (this || _global).parentNode;

      for (var i in (this || _global).__transition) if (+i !== id) return;

      if (parent) parent.removeChild(this || _global);
    };
  }

  var transition_remove = function () {
    return this.on("end.remove", removeFunction((this || _global)._id));
  };

  var transition_select = function (select) {
    var name = (this || _global)._name,
        id = (this || _global)._id;
    if (typeof select !== "function") select = d3Selection.selector(select);

    for (var groups = (this || _global)._groups, m = groups.length, subgroups = new Array(m), j = 0; j < m; ++j) {
      for (var group = groups[j], n = group.length, subgroup = subgroups[j] = new Array(n), node, subnode, i = 0; i < n; ++i) {
        if ((node = group[i]) && (subnode = select.call(node, node.__data__, i, group))) {
          if ("__data__" in node) subnode.__data__ = node.__data__;
          subgroup[i] = subnode;
          schedule(subgroup[i], name, id, i, subgroup, get(node, id));
        }
      }
    }

    return new Transition(subgroups, (this || _global)._parents, name, id);
  };

  var transition_selectAll = function (select) {
    var name = (this || _global)._name,
        id = (this || _global)._id;
    if (typeof select !== "function") select = d3Selection.selectorAll(select);

    for (var groups = (this || _global)._groups, m = groups.length, subgroups = [], parents = [], j = 0; j < m; ++j) {
      for (var group = groups[j], n = group.length, node, i = 0; i < n; ++i) {
        if (node = group[i]) {
          for (var children = select.call(node, node.__data__, i, group), child, inherit = get(node, id), k = 0, l = children.length; k < l; ++k) {
            if (child = children[k]) {
              schedule(child, name, id, k, children, inherit);
            }
          }

          subgroups.push(children);
          parents.push(node);
        }
      }
    }

    return new Transition(subgroups, parents, name, id);
  };

  var Selection = d3Selection.selection.prototype.constructor;

  var transition_selection = function () {
    return new Selection((this || _global)._groups, (this || _global)._parents);
  };

  function styleRemove(name, interpolate$$1) {
    var value00, value10, interpolate0;
    return function () {
      var value0 = d3Selection.style(this || _global, name),
          value1 = ((this || _global).style.removeProperty(name), d3Selection.style(this || _global, name));
      return value0 === value1 ? null : value0 === value00 && value1 === value10 ? interpolate0 : interpolate0 = interpolate$$1(value00 = value0, value10 = value1);
    };
  }

  function styleRemoveEnd(name) {
    return function () {
      (this || _global).style.removeProperty(name);
    };
  }

  function styleConstant(name, interpolate$$1, value1) {
    var value00, interpolate0;
    return function () {
      var value0 = d3Selection.style(this || _global, name);
      return value0 === value1 ? null : value0 === value00 ? interpolate0 : interpolate0 = interpolate$$1(value00 = value0, value1);
    };
  }

  function styleFunction(name, interpolate$$1, value) {
    var value00, value10, interpolate0;
    return function () {
      var value0 = d3Selection.style(this || _global, name),
          value1 = value(this || _global);
      if (value1 == null) value1 = ((this || _global).style.removeProperty(name), d3Selection.style(this || _global, name));
      return value0 === value1 ? null : value0 === value00 && value1 === value10 ? interpolate0 : interpolate0 = interpolate$$1(value00 = value0, value10 = value1);
    };
  }

  var transition_style = function (name, value, priority) {
    var i = (name += "") === "transform" ? d3Interpolate.interpolateTransformCss : interpolate;
    return value == null ? this.styleTween(name, styleRemove(name, i)).on("end.style." + name, styleRemoveEnd(name)) : this.styleTween(name, typeof value === "function" ? styleFunction(name, i, tweenValue(this || _global, "style." + name, value)) : styleConstant(name, i, value + ""), priority);
  };

  function styleTween(name, value, priority) {
    function tween() {
      var node = this || _global,
          i = value.apply(node, arguments);
      return i && function (t) {
        node.style.setProperty(name, i(t), priority);
      };
    }

    tween._value = value;
    return tween;
  }

  var transition_styleTween = function (name, value, priority) {
    var key = "style." + (name += "");
    if (arguments.length < 2) return (key = this.tween(key)) && key._value;
    if (value == null) return this.tween(key, null);
    if (typeof value !== "function") throw new Error();
    return this.tween(key, styleTween(name, value, priority == null ? "" : priority));
  };

  function textConstant(value) {
    return function () {
      (this || _global).textContent = value;
    };
  }

  function textFunction(value) {
    return function () {
      var value1 = value(this || _global);
      (this || _global).textContent = value1 == null ? "" : value1;
    };
  }

  var transition_text = function (value) {
    return this.tween("text", typeof value === "function" ? textFunction(tweenValue(this || _global, "text", value)) : textConstant(value == null ? "" : value + ""));
  };

  var transition_transition = function () {
    var name = (this || _global)._name,
        id0 = (this || _global)._id,
        id1 = newId();

    for (var groups = (this || _global)._groups, m = groups.length, j = 0; j < m; ++j) {
      for (var group = groups[j], n = group.length, node, i = 0; i < n; ++i) {
        if (node = group[i]) {
          var inherit = get(node, id0);
          schedule(node, name, id1, i, group, {
            time: inherit.time + inherit.delay + inherit.duration,
            delay: 0,
            duration: inherit.duration,
            ease: inherit.ease
          });
        }
      }
    }

    return new Transition(groups, (this || _global)._parents, name, id1);
  };

  var id = 0;

  function Transition(groups, parents, name, id) {
    (this || _global)._groups = groups;
    (this || _global)._parents = parents;
    (this || _global)._name = name;
    (this || _global)._id = id;
  }

  function transition(name) {
    return d3Selection.selection().transition(name);
  }

  function newId() {
    return ++id;
  }

  var selection_prototype = d3Selection.selection.prototype;
  Transition.prototype = transition.prototype = {
    constructor: Transition,
    select: transition_select,
    selectAll: transition_selectAll,
    filter: transition_filter,
    merge: transition_merge,
    selection: transition_selection,
    transition: transition_transition,
    call: selection_prototype.call,
    nodes: selection_prototype.nodes,
    node: selection_prototype.node,
    size: selection_prototype.size,
    empty: selection_prototype.empty,
    each: selection_prototype.each,
    on: transition_on,
    attr: transition_attr,
    attrTween: transition_attrTween,
    style: transition_style,
    styleTween: transition_styleTween,
    text: transition_text,
    remove: transition_remove,
    tween: transition_tween,
    delay: transition_delay,
    duration: transition_duration,
    ease: transition_ease
  };
  var defaultTiming = {
    time: null,
    // Set on use.
    delay: 0,
    duration: 250,
    ease: d3Ease.easeCubicInOut
  };

  function inherit(node, id) {
    var timing;

    while (!(timing = node.__transition) || !(timing = timing[id])) {
      if (!(node = node.parentNode)) {
        return defaultTiming.time = d3Timer.now(), defaultTiming;
      }
    }

    return timing;
  }

  var selection_transition = function (name) {
    var id, timing;

    if (name instanceof Transition) {
      id = name._id, name = name._name;
    } else {
      id = newId(), (timing = defaultTiming).time = d3Timer.now(), name = name == null ? null : name + "";
    }

    for (var groups = (this || _global)._groups, m = groups.length, j = 0; j < m; ++j) {
      for (var group = groups[j], n = group.length, node, i = 0; i < n; ++i) {
        if (node = group[i]) {
          schedule(node, name, id, i, group, timing || inherit(node, id));
        }
      }
    }

    return new Transition(groups, (this || _global)._parents, name, id);
  };

  d3Selection.selection.prototype.interrupt = selection_interrupt;
  d3Selection.selection.prototype.transition = selection_transition;
  var root = [null];

  var active = function (node, name) {
    var schedules = node.__transition,
        schedule,
        i;

    if (schedules) {
      name = name == null ? null : name + "";

      for (i in schedules) {
        if ((schedule = schedules[i]).state > SCHEDULED && schedule.name === name) {
          return new Transition([[node]], root, name, +i);
        }
      }
    }

    return null;
  };

  exports.transition = transition;
  exports.active = active;
  exports.interrupt = interrupt;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const transition = exports.transition,
      active = exports.active,
      interrupt = exports.interrupt,
      __esModule = exports.__esModule;